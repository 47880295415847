import React from "react"

import { APP_STORE_LINK, PLAY_STORE_LINK } from "../constants/linkConstants"
import AppStore from "../assets/app_store_icon.svg"
import PlayStore from "../assets/play_store_icon.svg"

const AppStoreIcons = ({ light, appStoreLink, playStoreLink }) => {
  return (
    <div style={{ marginTop: 40 }}>
      <h4 className={light && "white-text"}>Download The App:</h4>
      <a
        target="_blank"
        id="app_store_click_Apple"
        href={appStoreLink || APP_STORE_LINK}
      >
        <img
          className="app-store-icon left"
          src={AppStore}
          alt="Sinc Employee Time Tracking App Store"
        />
      </a>

      <a
        target="_blank"
        id="app_store_click_Apple"
        href={playStoreLink || PLAY_STORE_LINK}
      >
        <img
          className="app-store-icon"
          src={PlayStore}
          alt="Sinc Employee Time Tracking Play Store"
        />
      </a>
    </div>
  )
}

export default AppStoreIcons
