import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Categories from "./categories"
import SocialLinks from "../socialLinks"
import AboutUsSideBar from "../aboutUsSidebar"
import RecentPosts from "./recentPosts"

const query = graphql`
  {
    allStrapiArticle(limit: 5, sort: { fields: date, order: DESC }) {
      nodes {
        slug
        title
        date(formatString: "MMMM Do, YYYY")
      }
    }
  }
`

const BlogSideBar = ({ blogIndex }) => {
  const {
    allStrapiArticle: { nodes: recentPosts },
  } = useStaticQuery(query)
  return (
    <div>
      <div className="sidebar-section-wrapper divider">
        <SocialLinks />
      </div>
      <AboutUsSideBar />
      {!blogIndex && (
        <div className="sidebar-section-wrapper divider">
          <span className="title-text">More Posts:</span>
          <RecentPosts articles={recentPosts} />
        </div>
      )}
      <Categories />
    </div>
  )
}

export default BlogSideBar
