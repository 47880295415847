import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LoadableArea from "../../page-components/genericHero/LoadableArea"

const query = graphql`
  {
    hero: strapiHero {
      title
      subtitle
      buttonText
      imageAlt
      heroImage {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100, cropFocus: NORTH) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

const Hero = ({ appStoreLink, playStoreLink, signupLink }) => {
  const { hero } = useStaticQuery(query)
  return (
    <LoadableArea
      hero={hero}
      appStoreLink={appStoreLink}
      playStoreLink={playStoreLink}
      signupLink={signupLink}
    />
  )
  // )
}

export default Hero
