import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
const query = graphql`
  {
    categories: allStrapiCategory {
      distinct(field: name)
    }
  }
`

const Categories = () => {
  const {
    categories: { distinct },
  } = useStaticQuery(query)
  return (
    <div className="sidebar-section-wrapper">
      <span className="title-text">Categories</span>
      <ul>
        {distinct.map((category, index) => {
          let categorySlug = category.replace(/\s+/g, "-")
          return (
            <li key={index}>
              <Link to={`/blog/category/${categorySlug}`} className="category">
                <span className="category-list-item">{category}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Categories
