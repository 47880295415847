import React from "react"

import ResourceListItemCheck from "../../assets/resource_list_item_check.svg"

const ResourceListItem = ({ children }) => {
  let textClassName = "resource-list-item"
  return (
    <div className="renderer-resource-list-item-wrapper">
      <span className={textClassName}>{children}</span>
      <img src={ResourceListItemCheck} alt="check mark" />
    </div>
  )
}

export default ResourceListItem
