import React from "react"
import PropTypes from "prop-types"

import CheckWhite from "../assets/check-icon-white.svg"
import CheckBlue from "../assets/check-icon-blue.svg"

const ListItem = ({ children, white }) => {
  let textClassName = "renderer-list-item"
  if (white) {
    textClassName = "renderer-list-item white"
  }
  return (
    <div className="renderer-list-item-wrapper">
      <img src={white ? CheckWhite : CheckBlue} alt="check mark" />
      <span className={textClassName}>{children}</span>
    </div>
  )
}

ListItem.propTypes = {
  white: PropTypes.bool,
}

export default ListItem
