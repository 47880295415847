import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const AboutUsSideBar = () => {
  const query = graphql`
    {
      strapiSiteOverallMeta {
        aboutusblurb
      }
    }
  `

  const data = useStaticQuery(query)
  const {
    strapiSiteOverallMeta: { aboutusblurb },
  } = data

  return (
    <div className="sidebar-section-wrapper divider">
      <span className="title-text blue">About SINC</span>
      <p>{aboutusblurb}</p>
      <button className="btn">
        <Link to="/" id="blog-learn-more-cta">
          Learn More
        </Link>
      </button>
    </div>
  )
}

export default AboutUsSideBar
