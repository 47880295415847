import React from "react"
import { Link } from "gatsby"

const RecentPosts = ({ articles }) => (
  <ul>
    {articles.map(article => {
      return (
        <li>
          <Link to={`/blog/posts/${article.slug}`}>
            <div className="recent-post-item-wrapper">
              <span> {article.title}</span>
              <span className="recent-post-date"> {article.date}</span>
            </div>
          </Link>
        </li>
      )
    })}
  </ul>
)

export default RecentPosts
