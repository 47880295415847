import React from "react"
import styled from "styled-components"
import { Container, Row } from "react-grid-system"
import Image from "gatsby-image"

import AppStoreIcons from "../../appStoreIcons"
import Column from "../../layout/column"
import InnerContentContainer from "../../layout/innerContentContainer"

import { SIGN_UP_LINK } from "../../../constants/linkConstants"

const CenterAlignSmallColumn = styled(Column)`
  text-align: left;
  @media (max-width: 992px) {
    text-align: center;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
`

const CenterAlignH1 = styled.h1`
  text-align: left;
  max-width: 550px;
  @media (max-width: 992px) {
    text-align: center;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
`
const CenterAlignH3 = styled.h3`
  text-align: left;
  max-width: 440px;
  margin-top: 20px;
  @media (max-width: 992px) {
    text-align: center;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
`

const FullWidthImageBackground = styled.div`
display: flex;
justify-content: center;
align-items: center;
min-height: 520px;
position: relative;
background-image: url("/static/background-image.svg")};
background-size: contain;
background-repeat: no-repeat;
background-position: right -55px bottom -30px;
padding-top: 30px;
padding-bottom: 30px;
width: 100%;
@media(max-width: 1024px){
  padding: 30px 2rem;
}
@media(max-width: 576px){
  padding: 15px 2rem 30px;
}
`

const LoadableArea = ({ hero, appStoreLink, playStoreLink, signupLink }) => (
  <FullWidthImageBackground>
    <InnerContentContainer>
      <Container>
        <Row>
          <CenterAlignSmallColumn sm={12} md={12} lg={6}>
            <CenterAlignH1>{hero.title}</CenterAlignH1>
            <CenterAlignH3>{hero.subtitle}</CenterAlignH3>
            <button style={{ marginTop: 20 }} className="btn btn-cta large">
              <a href={signupLink || SIGN_UP_LINK} id="signup_hero_banner">
                {hero.buttonText}
              </a>
            </button>
            <AppStoreIcons
              appStoreLink={appStoreLink}
              playStoreLink={playStoreLink}
            />
          </CenterAlignSmallColumn>
          <Column style={{ textAlign: "center" }} sm={12} md={12} lg={6}>
            <Image
              imgStyle={{
                maxHeight: 400,
                objectFit: "contain",
              }}
              style={{
                margin: "0px auto",
              }}
              fadeIn={false}
              loading={"eager"}
              fluid={hero.heroImage.childImageSharp.fluid}
              alt={hero.imageAlt}
            />
          </Column>
        </Row>
      </Container>
    </InnerContentContainer>
  </FullWidthImageBackground>
)

export default LoadableArea
