import React from "react"
import Image from "gatsby-image"

const Avatar = ({ fixedAvatar, name, date, topPost }) => {
  const avatarWrapperClass = topPost ? "avatar-wrapper-small" : "avatar-wrapper"
  const wrapperClass = topPost
    ? "post-info-wrapper top-post"
    : "post-info-wrapper"
  const textWrapperClass = topPost
    ? "info-text-wrapper top-post"
    : "info-text-wrapper"
  return (
    <div className={wrapperClass}>
      <div className={avatarWrapperClass}>
        <Image fixed={fixedAvatar} />
      </div>
      <div className={textWrapperClass}>
        <span className="author">{name}</span>
        <span className="date">{date}</span>
      </div>
    </div>
  )
}
export default Avatar
