import React from "react"
import { Text, InlineImage, InfoArea } from "../components/blog/components"
import ListItem from "../components/listItem"
import ResourceListItem from "../components/resources/resourceListItem"

const { BlogH2, BlogH4, BlogCaption, BlogLink } = Text

export const testimonialRenderer = {
  p: props => {
    return (
      <p style={{ fontSize: "0.9rem", lineHeight: "1.5rem", color: "#e8e8e8" }}>
        {props.children}
      </p>
    )
  },
}

export const resourceRenderers = {
  li: props => {
    return <ResourceListItem>{props.children}</ResourceListItem>
  },
}

export const defaultRenderers = {
  li: props => {
    return <ListItem>{props.children}</ListItem>
  },
  p: props => {
    return <p>{props.children}</p>
  },
  h2: props => {
    return <h2>{props.children}</h2>
  },
}

export const defaultRenderersDarkBackground = {
  li: props => {
    return <ListItem white>{props.children}</ListItem>
  },
  p: props => {
    return <p className="white-text">{props.children}</p>
  },
  h2: props => {
    return <h2 className="white-text">{props.children}</h2>
  },
}

export const blogRenderers = {
  blockquote: props => {
    return <InfoArea>{props.children}</InfoArea>
  },

  li: props => {
    return <ListItem>{props.children}</ListItem>
  },
  img: props => {
    return <InlineImage src={props.src} alt={props.alt} />
  },
  h2: props => {
    return <BlogH2>{props.children}</BlogH2>
  },
  h3: props => {
    return <h3>{props.children}</h3>
  },
  h4: props => {
    return <BlogH4>{props.children}</BlogH4>
  },
  h6: props => {
    return <BlogCaption>{props.children}</BlogCaption>
  },
  a: props => {
    return <BlogLink href={props.href}> {props.children}</BlogLink>
  },
}
