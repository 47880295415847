import React from "react"
import styled from "styled-components"

const BlogH2 = ({ children }) => {
  return (
    <HeadingTwoWrapper>
      <h2 className="blog-title">{children}</h2>
      <div className="blog-underline"></div>
    </HeadingTwoWrapper>
  )
}

const HeadingTwoWrapper = styled.div`
  margin: 2rem 0;
  .underline {
    width: 5rem;
    height: 5px;
    background: var(--clr-primary-5);
  }
`

const BlogCaption = ({ children }) => {
  return (
    <BlogCaptionWrapper>
      <BlogCaptionText>{children}</BlogCaptionText>
    </BlogCaptionWrapper>
  )
}

const BlogCaptionText = styled.p`
  font-family: sans-serif;
`

const BlogCaptionWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: -20px;
  position: relative;
`

const BlogH4 = props => {
  return (
    <h3 style={{ margin: "2rem 0", color: "var(--clr-primary-5)" }}>
      {props.children}
    </h3>
  )
}

const BlogLink = props => {
  return (
    <a href={props.href} target="_blank">
      {props.children}
    </a>
  )
}

export { BlogH2, BlogH4, BlogCaption, BlogLink }
