import React from "react"
import styled from "styled-components"

const InlineImage = props => (
  <ImageWrapper>
    <Image src={props.src} alt={props.alt} />
  </ImageWrapper>
)

const Image = styled.img`
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  max-height: 350px;
`

const ImageWrapper = styled.div`
  margin-top: 20px;
  padding: 10px 0px;
  width: 100%;
  text-align: center;
`

export default InlineImage
