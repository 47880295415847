import React from "react"
import { graphql, Link } from "gatsby"
import Markdown from "react-markdown"
import gfm from "remark-gfm"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Avatar from "../components/blog/components/avatar"
import BlogSidebar from "../components/blog/blogSidebar"
import ArticleWrapper from "../components/layout/articleWrapper"
import Hero from "../components/home/hero"
import Image from "gatsby-image"
import { blogRenderers } from "../constants/mdxRenderers"

import {
  SIGN_UP_LINK,
  PLAY_STORE_LINK,
  APP_STORE_LINK,
  LOGIN_LINK,
} from "../constants/linkConstants"

const Article = ({ data }) => {
  const {
    strapiArticle: {
      SEO: { titleseo, description, created },
      title,
      content,
      image,
      categories,
      date,
      author,
      slug,
    },
    latestArticles: { nodes: recentPosts },
  } = data
  const signupLink = `${SIGN_UP_LINK}?utm_source=seo_content&utm_medium=organic&utm_campaign=blog&utm_content=${slug}`
  const appStoreLink = `${APP_STORE_LINK}?utm_source=seo_content&utm_medium=organic&utm_campaign=blog&utm_content=${slug}`
  const playStoreLink = `${PLAY_STORE_LINK}?utm_source=seo_content&utm_medium=organic&utm_campaign=blog&utm_content=${slug}`
  const loginLink = `${LOGIN_LINK}?utm_source=seo_content&utm_medium=organic&utm_campaign=blog&utm_content=${slug}`
  const fixedAvatar = author.avatar.childImageSharp.fixed
  return (
    <Layout signupLink={signupLink} loginLink={loginLink}>
      <SEO title={titleseo} description={description} article />
      <ArticleWrapper>
        <article>
          <Image className="blog" fluid={image.childImageSharp.fluid} />

          <div className="blog-post-info">
            <h2 className="blog-title bright-blue">{title}</h2>
            <div className="blog-author-and-categories-wrapper">
              <Avatar
                fixedAvatar={fixedAvatar}
                name={author.name}
                date={date}
              />
              <div className="post-categories-wrapper">
                {categories.map(category => {
                  let categorySlug = category.name.replace(/\s+/g, "-")
                  return (
                    <Link
                      to={`/blog/category/${categorySlug}`}
                      className="category"
                    >
                      <span className="category">{category.name}</span>
                    </Link>
                  )
                })}
              </div>
            </div>
            <div className="underline"></div>
          </div>
          <Markdown
            remarkPlugins={[gfm]}
            components={blogRenderers}
            children={content}
            className="blog"
          />
        </article>
        <BlogSidebar recentPosts={recentPosts} />
      </ArticleWrapper>
      <Hero
        signupLink={signupLink}
        appStoreLink={appStoreLink}
        playStoreLink={playStoreLink}
      />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleArticle($slug: String) {
    allFile {
      edges {
        node {
          localURL
        }
      }
    }
    latestArticles: allStrapiArticle(
      filter: { slug: { ne: $slug } }
      sort: { fields: date, order: DESC }
      limit: 3
    ) {
      nodes {
        title
        slug
        date(formatString: "MMMM Do, YYYY")
      }
    }
    strapiArticle(slug: { eq: $slug }) {
      title
      slug
      content
      image {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      SEO {
        titleseo
        description
      }
      date(formatString: "MMMM Do, YYYY")
      categories {
        name
      }
      author {
        name
        id
        avatar {
          childImageSharp {
            fixed(width: 40, height: 40, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

export default Article
